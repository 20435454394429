<template>
  <layout
      :page-settings="pageSettings"
      :preview="false"
  >
    <router-view :key="$route.fullPath" />
  </layout>
</template>

<script>
import Layout from "@account/components/Layout.vue";
import logApi from "@account/api/log.js";
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'App',
  components: {Layout},
  data() {
    return {
      pageSettings: {},
    };
  },
  mounted() {
    this.pageSettings = window.pageSettings;
    this.logVisit();
  },
  methods: {
    logVisit() {
      let identifier = localStorage.getItem('identifier');
      if (!identifier) {
        identifier = uuidv4();
        localStorage.setItem('identifier', identifier);
      }
      logApi.visit(window.account, {
        identifier,
        referrer: document.referrer,
      });
    }
  }
}
</script>
