<template>
  <div>
    <v-container
        v-for="(block, index) in pageBlocksComputed"
        fluid
        class="pl-0 pr-0 pt-3 pb-3"
        :style="block.themeComputed.outerContainerStyle || {}"
    >
      <v-container
          fluid
          class="pa-0"
          :style="block.themeComputed.innerContainerStyle || { 'max-width': '1200px' }"
      >
        <component
            v-if="componentExists(block.component)"
            v-bind:is="block.component"
            :ref="block.component + '_' + index"
            :block-id="block.id"
            :theme="block.themeComputed"
            :data="block.data"
            :preview="false"
            :account="account"
        ></component>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import account from "@account/mixins/account.js";

export default {
  name: 'Account',
  mixins: [ account ],
  data() {
    return {
      account: window.account,
    };
  },
}
</script>
