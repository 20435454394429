import api from './api';

export default {
    /**
     * Log user visit.
     *
     * @return Promise<Object>
     */
    visit(account, data) {
        return api
            .post('/api/accounts/' + account + '/log/visit', data)
            .then(response => response.data);
    },

}