/**
 * app.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */
import { registerPlugins } from '@account/plugins';
import { createApp } from 'vue';
import {createRouter, createWebHistory} from "vue-router";
import routes from './router/index.js';
import App from './App.vue';
import api from "@account/api/api.js";
import * as Sentry from '@sentry/vue';

const app = createApp(App)
const router = createRouter({
    history: createWebHistory(),
    routes,
})
api.get('sanctum/csrf-cookie');
registerPlugins(app)
app.use(router);

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    integrations: [Sentry.browserTracingIntegration({router}), Sentry.replayIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [import.meta.env.VITE_APP_DOMAIN, import.meta.env.VITE_CABINET_API_BASE_URL],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

app.mount('#app')
