import Account from "@account/views/Account.vue";

const routes = [
    {
        path: '/',
        component: Account
    },
    {
        path: '/:account',
        component: Account
    },
];
export default routes;